<template>
  <div id="home">
    <!-- 主体 -->
    <div id="main">
      <div id="main_in">
        <el-row>
          <el-col :span="24"><div class="grid-content bg-purple-dark"><div class="banner"><h1 class="h-one">产品创新 - 赋能医疗机构的心律诊疗与健康管理</h1></div></div></el-col>
        </el-row>
        <div id="mid_main">
          <div class="mid">
            <div v-for="item,i in dataTable" :key="i" class="mid_block">
              <div class="block_txt">
                <h2>
                  {{ item.title }}
                </h2>
                <p>
                  <i>{{ item.copy }}</i>
                </p>
              </div>
              <div class="block_img"><img :src="item.imgurl" alt="图片"></div>
            </div>
          </div>

          <div class="mid">
            <div v-for="item,i in dataTable2" :key="i" class="mid_block">
              <div class="block_txt">
                <h2>
                  {{ item.title }}
                </h2>
                <p>
                  <i>{{ item.copy }}</i>
                </p>
              </div>
              <div class="block_img"><img :src="item.imgurl" alt="图片"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="books">
      <h1>临床循证 - 提升心律诊疗与健康管理的准确性</h1>
      <h2>（越光产品与技术服务支持医疗机构临床应用已发表的各类论文）</h2>
      <div id="e_book">
        <div class="book one"><img src="../../public/EBook/syjz.jpg" ondragstart="return false" alt="沈阳军总-越光贴心-JACC2021-01-05"></div>
        <div class="book two"><img src="../../public/EBook/xd.png" ondragstart="return false" alt="长时间动态心电图对心肌梗死后心律失常的监测价值"></div>
        <div class="book three"><img src="../../public/EBook/xhyy.jpg" ondragstart="return false" alt="协和医院-越光贴心-AnnNoninvasiveECG"></div>
        <div class="book four"><img src="../../public/EBook/jydwx.jpg" ondragstart="return false" alt="越光大拇指-军医大文献"></div>
        <div class="book five"><img src="../../public/EBook/chma.jpg" ondragstart="return false" alt="越光大拇指-长海梅奥研究"></div>
        <div class="book six"><img src="../../public/EBook/whyx.jpg" ondragstart="return false" alt="武汉亚心-越光贴心-单导联长时程动态心电图的临床应用价值"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#main{
  background-image:-webkit-gradient(linear,left top, right top,from(lightgray),to(rgba(0,0,0,0.1))),url('../../public/img/banner.jpg');
  background-image:-o-linear-gradient(left,lightgray,rgba(0,0,0,0.1)),url('../../public/img/banner.jpg');
  background-image:linear-gradient(to right,lightgray,rgba(0,0,0,0.1)),url('../../public/img/banner.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin: 0px auto;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  padding-top: 60px;
}
#main_in{
  width: 90%;
  margin: 0 auto;
}
.banner{
  background-color: transparent;
  margin-top: 5%;
  padding: 5% 0px;
}
#main h1{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
	font-weight: 300;
	text-align: center;
  color: whitesmoke;
}
#mid_main{
  animation: mid 0.4s linear 0s;
  border-radius: 10px;
  text-align: center;
  height: 100%;
  width:100%;
  background-color: transparent;
  padding: 4% 0px;
}

#mid_main img:hover{
  -webkit-animation: move 0.5s linear;
          animation: move 0.5s linear;
}
.mid{
	margin: 0px auto;
	padding: 10px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.mid_block{
  margin: 5px;
	border-radius: 10px;
	display: flex;
	padding: 10px;
	width: 280px;
	align-items: center;
	justify-content: center;
	background-color: white;
}
.block_txt{
	text-align: center;
	width: 60%;
}
.block_img{
	width: 40%;
}
.block_img img{
	width: 100%;
	max-width: 100%;
	max-height: 100%;
}
h1,h2{
  text-align: center;
}
.h-one{
  animation: hone 0.3s linear 0s;
}
@keyframes hone {
  from{
    opacity: 0;
    transform: translateY(120px);
  }
  to{
    transform: translateY(0px);
  }
}
@keyframes mid {
  from{
    opacity: 0;
    transform: scale(0.7);
  }
  to{
    transform: scale(1);
  }
}
@-webkit-keyframes move {
  from{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25%{
    -webkit-transform: rotate(23deg);
            transform: rotate(23deg);
  }
  50%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75%{
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes move {
  from{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25%{
    -webkit-transform: rotate(23deg);
            transform: rotate(23deg);
  }
  50%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75%{
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
#mid h2,#mid i{
  margin-top: 12px;
  text-align: center;
}
#books{
  margin: 0px auto;
  height: auto !important;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 5%;
}
#books h1{
  margin: 2%;
  color: black !important;
}
#e_book{
  margin: 20px auto;
  background-color: white;
  height: 100%;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.book{
  width: 60%;
  margin-right: -5%;
}
.book img{
  border: 1px solid lightgray;
  -webkit-box-shadow: 2px 2px 5px 2px lightgray;
          box-shadow: 2px 2px 5px 2px lightgray;
  max-height: 100%;
  max-width: 100%;
}
.one{
  margin-right: 0vw;
  -webkit-transform:rotate(0deg);
      -ms-transform:rotate(0deg);
          transform:rotate(0deg);
  z-index: 12;
}
.two{
  -webkit-transform:rotate(0deg);
      -ms-transform:rotate(0deg);
          transform:rotate(0deg);
  z-index: 11;
}
.three{
  margin-left:4vw;
  -webkit-transform:rotate(1deg);
      -ms-transform:rotate(1deg);
          transform:rotate(1deg);
  z-index: 10;
}
.four{
  -webkit-transform:rotate(1deg);
      -ms-transform:rotate(1deg);
          transform:rotate(1deg);
  z-index: 9;
}
.five{
  -webkit-transform:rotate(2deg);
      -ms-transform:rotate(2deg);
          transform:rotate(2deg);
  z-index: 8;
}
.six{
  -webkit-transform:rotate(2deg);
      -ms-transform:rotate(2deg);
          transform:rotate(2deg);
  z-index: 7;
}
@media (max-width:765px){
  h1{
    font-size: 0.9em;
  }
  h2{
    font-size: 0.5em;
  }
  i{
    font-size: 0.5em;
  }
  .banner{
    padding: 5% 0px;
  }
  #books{
    padding: 10% 0px;
  }
  #mid img{
    height: 70%;
    width:70%;
  }
  #mid h2{
    font-size: 0.6em;
  }
  .grid-content{
    margin: -2px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      dataTable: [{ title: '临床诊断', copy: '普氏贴心®', imgurl: require('../../public/img/mid_1.png') },
        { title: '到家监测', copy: '普氏贴心®', imgurl: require('../../public/img/mid_2.png') },
        { title: '个人监测', copy: '大拇指®', imgurl: require('../../public/img/mid_3.png') }
      ],
      dataTable2: [{ title: '大众患教', copy: '健康律码', imgurl: require('../../public/img/mid_4.png') },
        { title: '数据管理', copy: '心电处理', imgurl: require('../../public/img/mid_5.png') },
        { title: '临床科研', copy: '定制设计', imgurl: require('../../public/img/mid_6.png') }
      ]
    }
  }
}
</script>
